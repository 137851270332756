<template>
  <div class="px-3 mt-3">
    <v-card v-if="card_visible" class="mx-auto push-detail mb-1">
      <p class="detailhead">{{head_title}}{{push_date}}</p>
      <Content :item="item" :contents_type="this.init.contents_type"></Content>
    </v-card>
    <v-overlay :value="loading">
      <div class="pt-5">
        <v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import Content from '@/views/notification/content.vue'
import moment from "moment"
export default {
  data: () => ({
    item: null,
    shop_name: '',
    push_date: null,
    badgeParams: {},
    head_title: '配信日時：',
    card_visible: false,
    loading: false,
  }),
  components: {
    Content
  },
  async created () {

    // GET パラメータを取得
    this.getUrlParam()

    //createdで、localStorageから全ての保存情報を取得しinitに格納
    this.init = this.storageGet('*')

    if (this.init.contents_type == 8) {
      // Webコンテンツ配信だけ遅いのでローディング表示
      this.loading = true
    } else {
      // Webコンテンツ配信以外はカードあり
      this.card_visible = true
    }

    if (this.init.push_id && this.init.push_id != 0) {
    
      // t_pushを取得 + t_user_push_logのcreate_date as push_date
      const user_push_params = { params:{
          'id': this.init.push_id,
          'preview': this.getUrlGetParam('preview'),
          'is_badges': 0,
        }}
      let user_push_res = await this.apiCall('/user/notification/detail', user_push_params);
      if (!user_push_res) return false;

      // バッジ数取得
      await this.callUserInitAndSendBadgeConditionToNative();

     // イベントお知らせは、/notices/detail1で上書き
      this.$set(this, 'item', user_push_res.data);

      // 配信日は、配信を行った日をセットする。
      //  従来、イベント・お知らせの時、その開始日が、
      //  新規作成の時、空欄が設定されていた。fujiwara
      this.push_date = moment(user_push_res.data.push_date).format('YYYY年MM月DD日 HH時mm分');
      this.shop_name = user_push_res.data.shop_name;
    }

    try{
      let res = null;
      if (this.init.contents_type == 1) {
        // イベントお知らせ
        const params = { params:{
            'id': this.init.contents_id,
            'preview': this.getUrlGetParam('preview'),
            'is_badges': 0,
          }}
        res = await this.apiCall('/notices/detail1', params);
        if (!res) return false;
        this.$set(this, 'item', res);
      } else if (this.init.contents_type == 8) {
        // Webコンテンツ配信
        window.location.replace(this.item.web_contents_url)
      }

    } catch(e) {
      this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }
    // this.loading = false
  },
  async mounted () {
    // DOM が更新されるのを待つ
    await this.$nextTick();

    // プレビューは後続処理不要
    if (this.getUrlGetParam('preview')) {
      return;
    }
  },
  methods: {
    onClickBtn(link) {
      this.$router.push(link)
    },
  }
}
</script>

<style scoped>
/* vue css override */
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 10px;
  margin-bottom: 40px;
}
/* original css */
.push-detail {
  background-color: #E9D7B8;
  padding-top: 10px;
  box-shadow: none !important;
}
.detailhead {
  text-align: center;
  font-size: 11px;
  margin: 0 0 10px;
}
.topbar {
  height: 15px;
  margin-bottom: 15px;
}
.vdbar {
  width: 94%;
  margin: 16px auto 0;
}
.push_title {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #333;
  text-align: center;
  display: block;
  padding: 10px 16px 5px;
}
.push_contents {
  padding: 16px;
}
.v-card >>> .push_contents img {
  max-width: 100%;
}
</style>
