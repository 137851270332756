<template>
  <v-card class="mx-auto mb-12" outlined style="min-height: 85vh;">
    <v-card-title class="push_title nl2br px-3 pb-0 text-center" v-if="item && item.title">{{item.title}}</v-card-title>
    <v-card-text class="pt-3">
      <div v-if="item && item.image_url" class="noticeImg pt-0">
        <v-img :src="item.image_url" lazy-src="@/assets/nophoto.png" class="rounded elevation-2" />
      </div>
      <div class="push_contents" v-if="contents_type != 1 && contents_type != 2" v-html="(item || {}).push_comment"></div>
      <div class="push_contents" v-else-if="contents_type == 1 || contents_type == 2" v-html="_autoHyperLink(item.comment)"></div>
      <div class="push_contents" v-else v-html="(item || {}).comment"></div>
    </v-card-text>
    <div class="text-center pt-10 pb-4 mx-16 px-5">
      <v-btn rounded depressed outlined small block color="#808080" style="" @click.stop="routerBack('notification_list', {shop_id: item.shop_id})">閉じる</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
        type: Object,
        //required: true
    },
    contents_type: {
      default: Number
    }
  },
  methods: {
    _autoHyperLink(obj) {
      obj = this.removeTargetBlank(obj)
      return this.autoHyperLink(obj)
    },
  }
}
</script>

<style scoped>
/* vue css override */
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  /* border-radius: 10px; */
  border-radius: 0 0 10px 10px !important;
  margin-bottom: 40px;
}
/* original css */
.detailhead {
  text-align: center;
  font-size: 11px;
  margin: 0 0 10px;
}
.topbar {
  height: 15px;
  margin-bottom: 15px;
}
.vdbar {
  width: 94%;
  margin: 16px auto 0;
}
.push_title {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  display: block;
}
.push_contents {
  line-height: 1.25rem;
  font-size: 0.75rem;
  color: #333;
}
.push_contents::v-deep p {
  margin-bottom: 0 !important;
}
.noticeImg {
  padding: 16px;
}
.noticeImg img {
  max-width: 100%;
}
</style>
